.acces-container-all {
    background-color: rgb(229, 247, 248);
}

.titre-acces-container {
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.titre-acces {
    font-size: 50px;
}

.under-titre-acces {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.container-img-acces {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    flex-wrap: nowrap;
    padding-bottom: 50px;
}

.plan-acces {
    width: 70vw;
    margin: 10px;
}

.infos-acces {
    width: 30vw;
    margin: 10px;
}


@media screen and (max-width: 600px) {
    .container-img-acces {
        padding-left: 30px;
        padding-right: 30px;
        flex-wrap: wrap;
        padding-bottom: 30px;
    }

    .plan-acces {
        width: 80vw;
    }

    .infos-acces {
        width: 80vw;
    }
}

