
.ecoles-container-all {
    background-color: aliceblue;

}

.titre-ecoles-container {
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.titre-ecoles {
    font-size: 50px;
}

.under-titre-ecoles {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.container-logos-ecoles {
    display: flex;
    width: 100vw;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.logo-ecole {
    width: 220px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 20px;
    transition: all .2s ease-out;
    cursor: pointer;
}

.logo-ecole:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 650px) {
    .logo-ecole {
        width: 115px;
    }
}

@media screen and (max-width: 400px) {
    .logo-ecole {
        width: 75px;
    }
}

.logo-ecole > img {
    max-width: 100%;
}

