
.user-intro-container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.user-intro-infos-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: 500px;
    padding: 10px;
    margin-bottom: 30px;
    border: solid 2px rgb(200, 212, 218);
    border-radius: 7px;
}

.user-intro-name {
    font-size: large;
    color: cadetblue;
    margin-right: 10px;
}

.user-intro-last-name {
    font-weight: bold;
}

.user-intro-ent {
    font-size: larger;
    color: darkslategray;
    font-weight: bolder;

}

.user-intro-disclaimer {
    font-style: italic;
}