.type-grid-container {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: white;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr 1fr; */
    height: 80vw;
    column-gap: 10px;
    row-gap: 10px;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 30px;
    padding-bottom: 10px;
}

@media screen and (max-width: 550px) {
    .type-grid-container {
        column-gap: 5px;
        row-gap: 5px;
        grid-template-columns: 1fr;
        height: 200vw;
    }
}


.grid-item-bg {
    align-self: stretch;
    justify-self: stretch;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.grid-item-txt {
    display: flex;
    font-size: larger;
    justify-content: center;
    align-items: center;
    background-color: rgba(2, 0, 80, 0.);
    color: rgb(194, 238, 255, 0);
    width: 100%;
    height: 100%;
}

.all-types-box {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    height: 350px;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-bottom: 100px;
}

.all-types-bg {
    align-self: stretch;
    justify-self: stretch;
    background-size: 100%;
    background-position: 50% 50%;
    cursor: pointer;
    padding: 30px;
    padding-right: 100px;
    padding-left: 100px;
}

.all-types-txt {
    font-size: larger;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(194, 238, 255);
    width: 100%;
    height: 100%;
    transition: all .3s ease-out;
}


@media screen and (max-width: 550px) {
    .grid-item-txt {
        color: rgb(194, 238, 255, 1);
        background-color: rgba(6, 5, 41, 0.6);
    }

    .all-types-box {
        height: 200px;
        padding-bottom: 50px;
    }

    .all-types-bg {
        padding: 0px;
    }
}

@media screen and (min-width: 550px) {
    .grid-item-txt {
        transition: all .3s ease-out;
    }

    .grid-item-txt:hover {
        color: rgb(194, 238, 255, 1);
        background-color: rgba(6, 5, 41, 0.6);
    }

    .all-types-bg:hover .all-types-txt {
        transform: scale(1.2);
    }
}
