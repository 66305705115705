.forum-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.forum-text-container {
    background-color: aliceblue;
    width: 50vw;
}

@media screen and (max-width: 700px) {
    .forum-text-container {
        width: 100vw;
    }
}

.forum-titre-container {
    padding-top: 60px;
    padding-bottom: 60px;
    display: block;
    padding-left: 5vw;
}

.titre-forum {
    font-size: 50px;
}

.under-titre-forum {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.forum-chiffres-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(119, 189, 211);
    width: 100vw;
    padding-top: 50px;
    padding-bottom: 40px;
}

.chiffres-et-legende {
    display: block;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

.chiffre-forum {
    font-size: 50px;
    font-weight: 500;
}

.legende-chiffre-forum {
    font-size: 30px;
}


@media screen and (min-width: 900px) {
    .separation-chiffres {
        width: 3px;
        height: 60px;
        background-color: black;
        margin-top: 10px;
        flex-shrink: 10;
    }
}

.text-descr-forum {
    text-align: justify;
    text-justify: distribute-all-lines;
    font-size: 20px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 60px;
    padding-bottom: 60px;
}

.flex-button-atrium-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    flex-wrap: wrap;
}

.button-atrium {
    padding: 10px;
}