.single-page-ent-container {
    background-color: aliceblue;
    padding: 5%;
    padding-top: 2%;
    min-height: 100vh;
}

.single-page-ent-infos-all {
    /* display: flex; */
    width: 100%;
    max-height: 500px;
    padding-bottom: 50px;
    padding-top: 4%;

}

/* .single-page-ent-infos-all::after {
    content: "";
    clear: both;
    display: table;
} */

.single-page-ent-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.single-page-ent-name {
    font-size: 40px;
    color: rgb(32, 49, 88);
    font-weight: bold;
    padding-bottom: 10%;
    padding-top: 3%;
}

.single-page-ent-logo {
    max-width: 45%;
    max-height: 70%;
    margin: auto;
    padding-bottom: 10%;
}


.single-page-ent-picture {
    max-width: 50%;
    float: right;
    margin-bottom: 3%;
    margin-left: 5%;
}

.single-page-ent-contenu-container {
    font-size: 20px;
    /* text-align: justify; */
}

.single-page-ent-contenu {
    text-align: justify;
}


.single-page-ent-contenu-titre-container {
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 3%;
    padding-top: 2%;
}

.single-page-ent-contenu-titre {
    font-size: 50px;
}

.single-page-ent-contenu-under-titre {
    height: 5px;
    width: 100px;
    background-color: rgb(119, 189, 211);
    margin-top: 15px;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
    .single-page-ent-picture {
        max-width: 100%;
        float: none;
        margin-bottom: 0%;
        margin-left: 0%;
    }

    .single-page-ent-name {
        font-size: 25px;
    }

    .single-page-ent-infos-all {
        max-height: 1500px;
    }

    .single-page-ent-contenu-container {
        font-size: 12px;
    }

    .single-page-ent-contenu-titre {
        font-size: 30px;
    }
}