.footer-container {
    margin: 0;
    padding: 5%;
    padding-bottom: 1%;
    background-color: rgb(116, 116, 121);
}

.footer-line-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}


.logo-footer {
    max-height: 100px;
    width: auto;
}

.c-forum-trium {
    text-align: center;
    padding-top: 30px;
}

.reseaux-sociaux-footer {
    margin: 5px;
    transition: .2s ease-in-out;
}

.reseaux-sociaux-footer:hover {
    transform: scale(1.1);
}


@media screen and (max-width: 600px) {
    .footer-line-1 {
        flex-direction: column;
    }

    .logo-footer {
        margin-bottom: 30px;
    }

    .reseaux-sociaux-footer {
        margin-top: 30px;
    }
}