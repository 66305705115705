.partenaires-container-all {
    background-color: rgb(250, 240, 231);
}

.titre-partenaires-container {
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.titre-partenaires {
    font-size: 50px;
}

.under-titre-partenaires {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.container-logos-partenaires {
    display: flex;
    width: 100vw;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 50px;
    flex-wrap: wrap;
}


.logo-partenaire {
    width: 150px;
    height: 150px;
    margin: 10px;
    cursor: pointer;
    transition: all .2s ease-out;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    /* padding-top: 100%; */
}

.logo-partenaire:hover {
    transform: scale(1.1);
}

/* .logo-partenaire>img {
    max-width: 100%;
} */

@media screen and (max-width: 800px) {
    .logo-partenaire {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 500px) {

    .container-logos-partenaires {
        padding-left: 20px;
        padding-right: 20px;
    }

    .logo-partenaire {
        width: 70px;
        height: 70px;
        margin: 5px;
    }
}

