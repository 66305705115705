
.bg-vid {
    position: fixed;
    z-index: -1;
    min-height: 100vh;
    min-width: 100vw;
    background-size: cover;
    background-position: 50% 50%;
    padding: 0;
    top: 0;
    filter: brightness(50%);
}

.main-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.main-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-trium {
    max-height: 35vh;
}

.text-main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: aliceblue;
    font-size: 40px;
    padding: 0;
    margin: 0;
}

.text-info > p {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.arrow-icon {
    transition: all .4s ease-in-out;
    color: aliceblue;
    font-size: 50px;
    cursor: pointer;
}

.arrow-icon:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.3);
}