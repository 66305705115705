
.custom-navbar {
    background-color: rgb(28, 39, 59);
    /* transition: top 0.6; */
    transition: all 1s ease-out;
    transition-delay: 1.5s;
    opacity: 0.6;
}

@media screen and (max-width: 1000px) {
    .custom-navbar {
        opacity: 1;
    }
}

.custom-navbar:hover {
    transition: all .2s ease-out;
    opacity: 1;
}


.brand-navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    min-width: 25vw;
    background-color: rgb(241, 240, 255);
    padding: 5px;
}

.nav-logo {
    height: 70px;
    width: auto;
    cursor: pointer;
}

.txt-brand-forum {
    color: rgb(13, 24, 69);
    margin: 0;
    padding: 0;
    text-align: center;
}

.custom-nav {
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.linkscroll-navbar {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.linkscroll-navbar-connexion {
    cursor: pointer;
    padding: 0px;
    margin-left: 30px;
}

@media screen and (max-width: 1000px) {
    .linkscroll-navbar-connexion {
        margin-left: 0px;
    }
}

.text-button-linkscroll {
    color: rgb(126, 192, 231);
}

.text-button-linkscroll:hover {
    color: rgb(194, 229, 240);
}

.linkscroll-navbar > .active-linkscroll {
    font-weight: 900;
    text-decoration: none;
}

.button-connexion {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}