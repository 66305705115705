.App {
    background-color: blanchedalmond;
}

.App > section > article > h3 {
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
}

.App > nav > section > h1 {
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
}

.div {
    padding: 0px;
    margin: 0px;
}