
.public-grid-container-all {
    background-color: white;
    padding: 5%;
}

.titre-grid-entreprises-container {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.titre-grid-entreprises {
    font-size: 50px;
    line-height: normal;
}

.under-titre-grid-entreprises {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.entreprises-grid-container {
    width: 100%;
    max-width: 1500px;
    min-width: 150px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    padding: 2% 5%;
    /* margin-left: 5%;
    margin-right: 5%; */
    grid-gap: 1vw;
}


@media screen and (max-width: 900px) {
    .entreprises-grid-container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media screen and (max-width: 500px) {
    .entreprises-grid-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (max-width: 350px) {
    .entreprises-grid-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.card-entreprise {
    background-color: rgb(212, 235, 253);
    width: 100%;
    transition: all .2s ease-out;
    /* height: 200px; */
    /* border-radius: 0 0 10% 10%; */
}

.card-entreprise:hover {
    transform: scale(1.05);
}

.card-logo {
    width: 100%;
    padding-top: 100%;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.card-name {
    color: rgb(14, 18, 34);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    cursor: pointer;
    /* font-size: 2px; */
}

.tabs-grid-entreprises {
    width: 200px;
    border-right: 1px solid gray;
}

.root-tabs-grid-entreprises {
    flex-grow: 1;
    /* width: 100%; */
    background-color: aliceblue;
    display: flex;
    /* width: 500; */
    /* height: 224 */
}

.zone-grid-tab-entreprises {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .tabs-grid-entreprises {
        width: 100%;
        border-right: none;
    }

    .root-tabs-grid-entreprises {
        flex-grow: 1;
        width: 100%;
        background-color: aliceblue;
        display: block;
        /* width: 500; */
        /* height: 224 */
    }

}