.contact-container-all {
    background-color: rgb(239, 237, 246);
}

.titre-contact-container {
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    padding-bottom: 30px;
}

.titre-contact {
    font-size: 50px;
}

.under-titre-contact {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}


.contact-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-form {
    border: solid rgb(95, 95, 95) 2px;
    margin: 30px;
    margin-top: 0px;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.contact-form-table {
    border-collapse: collapse;
}

td {
    padding: 5px 20px;
    font-weight: bold;
}

td > * {
    display: block;
}

.long-contact-input {
    width: 30vw;
    /* margin-bottom: 10px; */
}

.contact-field-textarea {
    height: 100px;
}

.contact-button-send {
    width: 30vw;
    background-color: rgb(119, 189, 211);
    border: solid rgb(116, 159, 238) 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
    font-weight: bold;
}

.error-contact-form {
    color: orangered;
}

.mail-reseaux-contact {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mail-reseaux-contact > * {
    padding-bottom: 10px;
    text-align: center;
}

.reseaux-sociaux-contact {
    margin: 5px;
    transition: .2s ease-in-out;
}

.reseaux-sociaux-contact:hover {
    transform: scale(1.1);
}