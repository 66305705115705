
.titre-entreprises-container {
    width: 100vw;
    background-color: white;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.titre-entreprises {
    font-size: 50px;
}

.under-titre-entreprises {
    height: 5px;
    width: 80px;
    background-color: rgb(119, 189, 211);
    margin-top: 10px;
    margin-bottom: 20px;
}

.entreprises-container-all {
    background-color: white;
}